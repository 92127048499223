<template>
  <div>
    <h1 class="mr-sm-4 header-tablepage mb-3">Ticket Setting</h1>

    <div class="title-tabs">Setting</div>
    <div class="bg-white p-2 p-lg-3">
      <div class="row">
        <div class="col-sm-6 col-12">
          <InputText
            textFloat="Auto Closed Tickets (Days)"
            name="auto_closed_tickets"
            placeholder="Auto Closed Tickets (Days)"
            type="number"
            v-model="form.auto_close"
          />
        </div>
        <div class="break-normal"></div>
        <div class="col-sm-6 col-12">
          <div class="input-action admin">
            <InputText
              :textFloat="''"
              v-model="form.admin_name"
              placeholder="Assign Admin"
              type="text"
              readonly
              custom-label
              @inputClick="handleAssignTo()"
            >
              <template #textFloat>
                <div class="custom-label">
                  <label> Auto Assign Agent to Ticket (From Email)</label>
                </div>
              </template>
            </InputText>

            <div class="input-action-icon">
              <b-icon
                stacked
                icon="search"
                scale="0.5"
                variant="grey"
                @click="handleAssignTo()"
              ></b-icon>
            </div>
          </div>
        </div>
        <b-button
          @click="removeAgent()"
          variant="custom-link"
          v-if="form.admin"
        >
          <font-awesome-icon
            icon="trash-alt"
            class="text-danger mr-2"
          ></font-awesome-icon
          >Remove Agent
        </b-button>
        <div class="col-12">
          <b-form-checkbox
            switch
            v-model="form.tigger_email_assign_ticket"
            unchecked-value="0"
            value="1"
          >
            Trigger Email - Assign Ticket
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <ModalAdminList
      :ref="`adminModal`"
      :selectedAdmin="form.admin"
      @select="handleSelectedAdmin"
    />
    <FooterAction @submit="submit" routePath="/setting" />
  </div>
</template>

<script>
import ModalAdminList from "@/components/ticket/activity/ModalAdminList";
export default {
  components: { ModalAdminList },
  data() {
    return {
      form: {
        auto_close: "",
        admin: "",
        admin_name: "",
        tigger_email_assign_ticket: 0,
      },
      selectedAdmin: "",
    };
  },

  created() {
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      const res = await this.axios("/ticket/setting");
      this.form = res.data.detail;
      // this.form.admin_name = "";
    },
    async submit() {
      this.$bus.$emit("showLoading");
      if (this.form.auto_close == "") this.form.auto_close = 0;
      const res = await this.axios.put("/ticket/setting", this.form);
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.successAlert();
        this.$router.push("/setting");
      } else {
        this.errorAlert(res.data.message);
      }
    },
    async onAssignToMeChange(value, index) {
      this.selectedIndex = index;
      if (value == 1) {
        this.handleSelectedAdmin(this.adminData, true);
      } else {
        this.form.admin = null;
        this.form.admin_name = "";
        this.$forceUpdate();
      }
    },
    handleAssignTo(index, value) {
      this.$refs[`adminModal`].show();
    },
    handleSelectedAdmin(admin, isAssignTome = false) {
      if (this.form.admin == admin.user_guid) {
        this.form.admin_name = "";
        this.form.admin = "";
        this.$forceUpdate();
      } else {
        this.form.admin_name = [admin.name, admin.telephone]
          .filter((el) => el)
          .join(", ");
        this.form.admin = admin.user_guid;

        this.$forceUpdate();
      }
    },
    removeAgent() {
      console.log(this.form);
      this.form.admin = "";
      this.form.admin_name = "";
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.break-ele {
  flex-basis: 100%;
  height: 0;
}
.font-bold {
  font-weight: bold;
}
.input-action {
  position: relative;
  width: 100%;

  .input-custom input {
    cursor: pointer !important;
  }

  .input-action-icon {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 27px;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: inherit;
    color: var(--primary-color);
  }
  .input-action-delete-icon {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 32px;
    right: -50px;
    width: 32px;
    height: 32px;
    background-color: inherit;
    color: var(--primary-color);
  }

  &.admin {
    .input-action-icon {
      top: 27px;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
</style>
